<template>
    <div class="col-12 vh-100">
        <div class="row">
            <div class="d-flex align-items-end flex-column vh-100 p-0 conversation">
                <div class="chatbot-header-outer-padding">
                    <div class="chatbot-header-inner-bg">
                        <div class="whatsapp-header">
                            <h5 class="modal-title" id="staticBackdropLabel">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <img v-if="this.whatsappprofile != null && this.whatsappprofile != ''"
                                            :src="this.whatsappprofile" class="profile-header-avatar rounded-circle"
                                            alt="" width="46" height="46" />
                                        <img v-else src="/assets/images/goa-police-logo.png"
                                            class="profile-header-avatar rounded-circle" alt="" width="46"
                                            height="46" />
                                    </div>
                                    <div class="flex-grow-1" style="margin-left: 15px;">
                                        <div class="whatsapp-header-name text-capitalize"><img
                                                src="/assets/images/goa-police-name.png" alt="goa-police-label"
                                                height="17" /><img
                                                src="/assets/images/whatsapp-icon/material-symbols_verified-rounded.png"
                                                class="ms-1" alt="" width="17" height="17"
                                                style="vertical-align: top;" /></div>
                                        <div class="whatsapp-header-number">online</div>
                                    </div>
                                </div>
                            </h5>
                        </div>

                    </div>
                </div>
                <div class="chatbox-right-outer">
                    <div class="chatbox-inner-content" id="chatcontainer">
                        <div class="p-dialog-content">
                            <div class="conversation-container">
                                <div class="row">
                                    <div class="col-lg-3 col-md-6 col-12">
                                        <div class="custom-form-group">
                                            <label class="form-label">Mobile No.<span
                                                    class="text-danger">*</span></label>
                                            <div class="input-group custom-input-group">
                                                <div class="col-lg-3 col-md-3 col-3">
                                                    <Multiselect v-model="add.countrycode" :options="countryCodeList"
                                                        :searchable="true" label="label" :disabled="disabledmobinput || sendotpbtnloader" placeholder="Select"
                                                        class="multiselect-custom custom-title-multiselect"
                                                        :canClear="false" :closeOnSelect="true" :object="true"
                                                        noOptionsText="No Result" :canDeselect="false" style="font-family: Inter-Regular !important;
    font-weight: 400;" />
                                                </div>
                                                <div class="col-lg-9 col-md-9 col-9" v-if="add.countrycode.value == 101">
                                                    <input type="text" :disabled="disabledmobinput || sendotpbtnloader"
                                                        v-model="add.visitormobile" class="form-control"
                                                        id="webChatMobileNoInput" placeholder="Enter Mobile No."
                                                        maxlength="10" @paste="onPasteMobile"
                                                        @keypress="onlyNumberMobile" autocomplete="off" />
                                                </div>
                                                <div class="col-lg-9 col-md-9 col-9" v-else>
                                                    <input type="text" :disabled="disabledmobinput || sendotpbtnloader"
                                                        v-model="add.visitormobile" class="form-control"
                                                         placeholder="Enter Mobile No."
                                                        minlength="10"
                                                        maxlength="15"
                                                        @keypress="onlyNumber" @paste="onPasteMobile" autocomplete="off" />
                                                </div>
                                            </div>
                                            <div class="custom-error"
                                                v-if="v$.add.visitormobile.$error && add.countrycode != ''">
                                                {{ v$.add.visitormobile.$errors[0].$message }}
                                            </div>
                                            <div class="custom-error" v-if="v$.add.countrycode.$error">
                                                {{ v$.add.countrycode.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-12 col-12 btn-marign-top" v-if="!disabledmobinput">
                                        <div class="custom-form-group mb-0">
                                            <button type="button" class="header-btn-outer btn btn-primary"
                                                @click="sendMobileOtp()" :disabled="sendotpbtnloader">
                                                <span v-if="!sendotpbtnloader">Submit</span>
                                                <div class="
                                                    spinner-border
                                                    text-light
                                                    custom-spinner-loader-btn
                                                " role="status" v-if="sendotpbtnloader">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-12" v-if="otpShowFlag">
                                        <div class="custom-form-group">
                                            <label for="formaadharcardinput"
                                                class="form-label d-flex align-items-center justify-content-between"><span>Enter
                                                    OTP <span class="text-danger">*</span></span><span
                                                    v-if="timerMobileStatus" class="otp-counter-text mt-1">{{
                                            timerMobileInterval
                                        }}</span></label>
                                            <input type="text" v-model="mobileotp" class="form-control otp-input-space"
                                                id="formaadharcardinput" placeholder="_         _         _         _"
                                                autocomplete="off" maxlength="4" minlength="4" @keypress="onlyNumber"
                                                @paste="onPasteOtp" :disabled="showresentloader || verifybtnloader" />
                                                <div v-if="otpvalue" class="text-success text-end"> {{ this.otpvalue }}</div>
                                            <div class="text-center">
                                                <button @click="resendMobileOtp()"
                                                    class="btn btn-link aadhar-resent-otp-btn"
                                                    v-if="resentotpbtnstatus && this.resendbtndisabled != 2"
                                                    :disabled="showresentloader">
                                                    <span v-if="!showresentloader">Resend OTP</span>
                                                    <div class="spinner-border custom-spinner-icon-loader-btn"
                                                        role="status" v-if="showresentloader">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </div>
                                                </button>
                                            </div>
                                            <span class="custom-error" v-if="mobileotperr">
                                                {{ mobileotperr }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12 text-success d-lg-none d-xs-block d-sm-block d-md-block"
                                        v-if="successMsg">
                                        {{ this.successMsg }}
                                    </div>
                                    <div class="col-lg-3 col-md-12 col-12 btn-marign-top" v-if="otpShowFlag">
                                        <div class="custom-form-group mb-0">
                                            <button type="button" class="header-btn-outer btn btn-success"
                                                @click="verifyMobileOtp()"
                                                :disabled="mobileotp.length != 4 || verifybtnloader">
                                                <span v-if="!verifybtnloader">Verify</span>
                                                <div class="
                                                    spinner-border
                                                    text-light
                                                    custom-spinner-loader-btn
                                                " role="status" v-if="verifybtnloader">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 text-success d-lg-block d-none" v-if="successMsg">
                                        {{ this.successMsg}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, minLength, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../service/ApiService";
export default {
    data() {
        return {
            v$: useValidate(),
            countryCodeList: [],
            add: {
                countrycode: { value: 101, label: "+91", maj2: "India" },
                visitormobile: "",
            },
            mobileotp: '',
            sendotpbtnloader: false,
            successMsg: '',
            disabledmobinput: false,
            timerMobileInterval: null,
            timerMobileCount: 120,
            timerMobileStatus: false,
            mobileotperr: '',
            otpShowFlag: false,
            showresentloader: false,
            resentotpbtnstatus: false,
            verifybtnloader: false,
            rowid: '',
            encryptedid: '',
            resendbtndisabled: '',
            whatsappname: "Goa Police",
            whatsappprofile: '',
            otpvalue: '',
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    validations() {
        return {
            add: {
                visitormobile: { required: helpers.withMessage('Please enter Mobile No.', required), minLength: helpers.withMessage('Enter vaild Mobile No.', minLength(10)) },
                countrycode: { required: helpers.withMessage("Please select country code", required)},
            },
        };
    },
    mounted() {
        this.getWebCountryCode();
    },
    watch: {
        "add.visitormobile"(mobileNumber) {
            this.add.visitormobile = mobileNumber.replace(/[^0-9]/g, "")
        }
    },
    methods: {
        getWebCountryCode(e) {
            this.ApiService.getWebCountryCode(e).then((data) => {
                if (data.status == 200) {
                    this.countryCodeList = data.data;
                } else {
                    this.countryCodeList = '';
                }
            });
        },
        sendMobileOtp() {
            this.v$.add.$validate();
            let fields = {};
            fields["country"] = this.add.countrycode;
            fields["aa13"] = this.add.visitormobile;
            if (!this.v$.add.$error) {
                this.sendotpbtnloader = true;
                this.ApiService.verifyMobileNumber(fields).then((data) => {
                    if (data.status == 200) {
                        this.sendotpbtnloader = false;
                        this.rowid = data.data.aa1;
                        this.resendbtndisabled = data.data.aa63;
                        this.otpvalue = data.data.otp;
                        this.disabledmobinput = true;
                        this.otpShowFlag = true;
                        this.startMobileTimer();
                        this.timerMobileStatus = true;
                        this.successMsg = data.message;
                        setTimeout(() => {
                            this.successMsg = '';
                        }, 5000);
                    } else {
                        this.sendotpbtnloader = false;
                        this.disabledmobinput = false;
                        this.otpShowFlag = false;
                        this.successMsg = '';
                        this.otpvalue = '';
                    }
                });
            } else {
                this.$error;
            }
        },
        resendMobileOtp() {
            this.v$.add.$validate();
            this.mobileotperr = '';
            this.otpvalue = '';
            let fields = {};
            fields["aa13"] = this.add.visitormobile;
            this.resendbtndisabled = this.resendbtndisabled + 1;
            fields["aa63"] = this.resendbtndisabled;
            fields["aa1"] = this.rowid;
            if (!this.v$.add.$error) {
                this.showresentloader = true;
                this.ApiService.verifyMobileNumber(fields).then((data) => {
                    if (data.status == 200) {
                        this.resentotpbtnstatus = false;
                        this.showresentloader = false;
                        this.rowid = data.data.aa1;
                        this.resendbtndisabled = data.data.aa63;
                        this.otpvalue = data.data.otp;
                        this.startMobileTimer();
                        this.timerMobileStatus = true;
                        this.successMsg = data.message;
                        setTimeout(() => {
                            this.successMsg = '';
                        }, 5000);
                    } else {
                        this.showresentloader = false;
                        this.successMsg = '';
                    }
                });
            } else {
                this.$error;
            }
        },
        startMobileTimer() {
            this.timerMobileStatus = true;
            if (this.timerMobileCount > 0) {
                setTimeout(() => {
                    this.timerMobileCount--;
                    this.startMobileTimer();
                }, 1000);
                const minutes = Math.floor(this.timerMobileCount / 60);
                let seconds = this.timerMobileCount % 60;
                if (seconds < 10) {
                    this.timerMobileInterval = `0${minutes}:0${seconds}`;
                } else {
                    this.timerMobileInterval = `0${minutes}:${seconds}`;
                }
            } else {
                this.timerMobileCount = 120;
                this.timerMobileStatus = false;
                this.mobileotp = '';
                this.resentotpbtnstatus = true;
            }
        },
        verifyMobileOtp() {
            let fields = {};
            fields["country"] = this.add.countrycode;
            fields["aa1"] = this.rowid;
            fields["otp"] = this.mobileotp;
            this.verifybtnloader = true;
            this.ApiService.verifyOtp(fields).then((data) => {
                if (data.status == 200) {
                    this.verifybtnloader = false;
                    this.successMsg = data.message;
                    this.encryptedid = data.data;
                    this.redirectToChatBot();
                    this.resentotpbtnstatus = false;
                    this.timerMobileStatus = false;
                    this.timerMobileCount = 0;
                    this.add.visitormobile = '';
                    this.mobileotp = '';
                    this.mobileotperr = '';
                    this.sendotpbtnloader = false;
                    this.disabledmobinput = false;
                    this.otpShowFlag = false;
                    this.showresentloader = false;
                    this.rowid = '';
                    this.otpvalue = '';
                } else {
                    this.verifybtnloader = false;
                    this.mobileotperr = data.message;
                    this.mobileotp = '';
                    this.timerMobileStatus = false;
                    this.timerMobileCount = 0;
                    this.successMsg = '';
                }
            });
        },
        redirectToChatBot() {
            this.$router.push(`chatbot?value=${this.encryptedid}`);
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
        onlyNumberMobile($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var mobileInput = document.getElementById("webChatMobileNoInput");
            if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        onPasteOtp(event) {
            let otpvalue = event.clipboardData.getData('text');
            if (/^[0-9 ]+$/.test(otpvalue)) {
                let value = otpvalue.replace(/\s/g, '');
                this.mobileotp = value.slice(0, 4);
                return true;
            }
            else event.preventDefault();
        },
        onPasteMobile(event) {
            let mobvalue = event.clipboardData.getData('text');
            if (/^[0-9 ]+$/.test(mobvalue)) {
                let value = mobvalue.replace(/\s/g, '');
                this.add.visitormobile = value.slice(0, 10);
                return true;
            }
            else event.preventDefault();
        },
    },
};
</script>
<style scoped>
.chatbox-right-outer .form-label {
    font-family: 'Inter-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #070829;
}

.custom-form-group .otp-input-space {
    letter-spacing: 20px;
    text-align: center;
    border: 0;
}

.custom-form-group .otp-input-space::placeholder {
    text-align: center;
}

.header-btn-outer {
    min-width: 100px;
    background: #4849A1;
}

@media (min-width: 320px) and (max-width: 1024px) {
    .header-btn-outer {
        width: 100%;
    }

    .btn-marign-top {
        margin-top: 0;
    }
}




.btn-marign-top {
    margin-top: 26px;
}


/* new chatbot css start here */
.chatbot-header-outer-padding {
    font-family: 'Inter-Regular';
    background-color: #ffffff;
    width: 100%;
}

.whatsapp-header {
    padding: 13px 20.5px;
    gap: 70px;
    border-radius: 12px;
    box-shadow: 0px 1px 5.6px 0px #00000026;
    background: #FFFFFF;
    height: 72px;
}

.whatsapp-header-name {
    font-family: 'Inter-Regular';
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    color: #000000;
    margin-bottom: 6px;
    height: 21px;
    padding-top: 4px;
}

.whatsapp-header-number {
    font-family: 'Inter-Regular';
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #C4C4D5;
    height: 15px;
}

.chatbot-header-inner-bg {
    padding: 21px 15px 19px 15px;
    background: linear-gradient(179.44deg, #FFFFFF 29.31%, rgba(255, 255, 255, 0.01) 89.07%);
}

.conversation {
    height: calc(100% - 12px);
    position: relative;
    background: url("/assets/images/whatsapp-icon/bg-chatbot.png") no-repeat;
    z-index: 0;
    background-size: contain;
}

.form-control::placeholder {
    color: #707070;
    opacity: 1;
    font-size: 12px;
    font-family: 'Inter-Regular';
    text-align: start;
    text-transform: capitalize;
    line-height: 14.52px;
}

.otp-counter-text {
    font-family: 'Inter-Regular';
    font-weight: 700;
    color: #4849A1;
}

.aadhar-resent-otp-btn {
    font-family: 'Inter-Regular';
    font-weight: 400;
    text-decoration: none;
    margin-top: 20px;
}

.conversation-container {
    height: calc(100% - 5px);
    padding: 0 25px;
}

/* #old css start here */

.chatbox-right-outer {
    width: 100%;
    max-width: 100%;
    opacity: 1;
    padding: 0;
    height: 100%;
    overflow: auto;
    font-family: 'Inter-Regular';
    font-size: 14px;
    font-weight: 400;
}

.header-btn-outer,
.custom-form-group .form-control,
.custom-form-group .custom-error,
.custom-form-group .multiselect-dropdown {
    font-family: 'Inter-Regular';
    font-weight: 400;
}

.chatbox-right-outer .chatbox-inner-content {
    height: calc(100% - 0px);
    /* box-shadow: inset 0 10px 10px -10px #000000; */
    padding: 2px 0;
    overflow: auto;
    position: relative;
}

.p-dialog-content {
    padding: 0;
    height: 100%;
}

.whatsapp-header .modal-title {
    color: #ffffff;
}

.conversation-container::-webkit-scrollbar,
.p-dialog-content::-webkit-scrollbar,
.input-msg::-webkit-scrollbar {
    transition: all 0.5s !important;
    width: 3px !important;
    height: 3px !important;
    z-index: 10 !important;
}

.conversation-container::-webkit-scrollbar-thumb,
.p-dialog-content::-webkit-scrollbar-thumb,
.input-msg::-webkit-scrollbar-thumb {
    background: #c54d4d !important;
    /* background: #7f7777 !important; */
}
</style>